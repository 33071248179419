import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ReservationReport, ReserveIndicators } from '../../../api/marketx';
import { formatNumber3, formatPriceCur } from '@mx-ui/helpers';
import Skeleton from '@mui/material/Skeleton';

interface ReserveReportWidgetProps {
  report: ReservationReport;
  isLoading: boolean;
  indicators: ReserveIndicators | null;
}

export const dodMomHelper = (cur?: number, prev?: number): number => {
  if (typeof cur === 'number' && typeof prev === 'number') {
    return Math.round(((cur - prev) / prev) * 100);
  }
  return 0;
};

export const ReserveReportWidget: FC<ReserveReportWidgetProps> = observer(({ report, isLoading, indicators }): JSX.Element => {
  const reportAll = report?.rows?.filter(r => r.budgetGroupCode === 'all')?.[0] || {};
  const dod = {
    quantityTne: dodMomHelper(indicators?.dodSelectedDay?.quantityTne, indicators?.dodPreviousDay?.quantityTne),
    maxReserveDays: dodMomHelper(indicators?.dodSelectedDay?.maxReserveDays, indicators?.dodPreviousDay?.maxReserveDays),
    avgReserveDays: dodMomHelper(indicators?.dodSelectedDay?.avgReserveDays, indicators?.dodPreviousDay?.avgReserveDays),
    purchaseTotalCost: dodMomHelper(indicators?.dodSelectedDay?.purchaseTotalCost, indicators?.dodPreviousDay?.purchaseTotalCost),
    mrcTotalCost: dodMomHelper(indicators?.dodSelectedDay?.mrcTotalCost, indicators?.dodPreviousDay?.mrcTotalCost),
  };
  const mom = {
    quantityTne: dodMomHelper(indicators?.momSelectedMonth?.quantityTne, indicators?.momPreviousMonth?.quantityTne),
    maxReserveDays: dodMomHelper(indicators?.momSelectedMonth?.maxReserveDays, indicators?.momPreviousMonth?.maxReserveDays),
    avgReserveDays: dodMomHelper(indicators?.momSelectedMonth?.avgReserveDays, indicators?.momPreviousMonth?.avgReserveDays),
    purchaseTotalCost: dodMomHelper(indicators?.momSelectedMonth?.purchaseTotalCost, indicators?.momPreviousMonth?.purchaseTotalCost),
    mrcTotalCost: dodMomHelper(indicators?.momSelectedMonth?.mrcTotalCost, indicators?.momPreviousMonth?.mrcTotalCost),
  };

  return (
    <Grid maxWidth={'500px'} minWidth={'220px'}>
      <Paper sx={{ p: 1 }}>
        <Grid container>
          <Grid item xs={12} mb={1}>
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant={'body1'} fontWeight={500}>
                  Вес, т
                </Typography>
              </Grid>
              <Grid item>
                {isLoading ? (
                  <Grid height={'40px'}>
                    <Skeleton width={'100px'} />
                  </Grid>
                ) : (
                  <Grid container alignItems={'center'}>
                    <Grid item mr={2}>
                      <Typography variant={'body1'}>{reportAll.quantityTne ? formatNumber3(reportAll.quantityTne) : '-'}</Typography>
                    </Grid>
                    <Grid item height={'40px'}>
                      {indicators && <IndicatorArrow dod={dod.quantityTne} mom={mom.quantityTne} />}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mb={1}>
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant={'body1'} fontWeight={500}>
                  Максимальный, дней
                </Typography>
              </Grid>
              <Grid item>
                {isLoading ? (
                  <Grid height={'40px'}>
                    {' '}
                    <Skeleton width={'100px'} />
                  </Grid>
                ) : (
                  <Grid container alignItems={'center'}>
                    <Grid item mr={2}>
                      <Typography variant={'body1'}>{reportAll.maxReserveDays || '-'}</Typography>
                    </Grid>
                    <Grid item height={'40px'}>
                      {indicators && <IndicatorArrow dod={dod.maxReserveDays} mom={mom.maxReserveDays} />}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mb={1}>
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant={'body1'} fontWeight={500}>
                  Средний, дней
                </Typography>
              </Grid>
              <Grid item>
                {isLoading ? (
                  <Grid height={'40px'}>
                    <Skeleton width={'100px'} />
                  </Grid>
                ) : (
                  <Grid container alignItems={'center'}>
                    <Grid item mr={2}>
                      <Typography variant={'body1'}>
                        {' '}
                        {reportAll.reservesCount > 0 && reportAll.reserveDaysTotal > 0
                          ? Math.round(reportAll.reserveDaysTotal / reportAll.reservesCount)
                          : '-'}
                      </Typography>
                    </Grid>
                    <Grid item height={'40px'}>
                      {indicators && <IndicatorArrow dod={dod.avgReserveDays} mom={mom.avgReserveDays} />}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mb={1}>
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant={'body1'} fontWeight={500}>
                  В ценах закупки
                </Typography>
              </Grid>
              <Grid item>
                {isLoading ? (
                  <Grid height={'40px'}>
                    <Skeleton width={'100px'} />
                  </Grid>
                ) : (
                  <Grid container alignItems={'center'}>
                    <Grid item mr={2}>
                      <Typography variant={'body1'}>
                        {reportAll.purchaseTotalCost ? formatPriceCur(reportAll.purchaseTotalCost) : '-'}
                      </Typography>
                    </Grid>
                    <Grid item height={'40px'}>
                      {indicators && <IndicatorArrow dod={dod.purchaseTotalCost} mom={mom.purchaseTotalCost} />}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant={'body1'} fontWeight={500}>
                  В МРЦ
                </Typography>
              </Grid>
              <Grid item>
                {isLoading ? (
                  <Grid height={'40px'}>
                    <Skeleton width={'100px'} />
                  </Grid>
                ) : (
                  <Grid container alignItems={'center'}>
                    <Grid item mr={2}>
                      <Typography variant={'body1'}>{reportAll.mrcTotalCost ? formatPriceCur(reportAll.mrcTotalCost) : '-'}</Typography>
                    </Grid>
                    <Grid item height={'40px'}>
                      {indicators && <IndicatorArrow dod={dod.mrcTotalCost} mom={mom.mrcTotalCost} />}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
});

export const IndicatorArrow: FC<{ dod: number; mom: number }> = ({ dod, mom }): JSX.Element => {
  return (
    <Grid container flexDirection={'column'} flexWrap={'nowrap'}>
      <Grid item>
        <Grid
          container
          alignItems={'center'}
          position={'relative'}
          sx={
            mom
              ? {
                  '&:after': {
                    content: mom < 0 ? '"▼"' : '"▲"',
                    position: 'absolute',
                    top: '50%',
                    right: '0',
                    color: mom < 0 ? '#BCE424' : '#DC2B28',
                    fontSize: '14px',
                    transform: 'translateY(-55%)',
                  },
                }
              : {}
          }
        >
          <Typography color={'text.secondary'} variant={'caption'} fontSize={'10px'} minWidth={'28px'}>
            MoM
          </Typography>
          <Typography color={'text.secondary'} variant={'caption'} minWidth={'51px'}>
            {Math.abs(mom)}%
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          item
          container
          alignItems={'center'}
          position={'relative'}
          sx={
            dod
              ? {
                  '&:after': {
                    content: dod < 0 ? '"▼"' : '"▲"',
                    position: 'absolute',
                    top: '50%',
                    right: '0',
                    color: dod < 0 ? '#BCE424' : '#DC2B28',
                    fontSize: '14px',
                    transform: 'translateY(-55%)',
                  },
                }
              : {}
          }
        >
          <Typography color={'text.secondary'} variant={'caption'} fontSize={'10px'} minWidth={'28px'}>
            DoD
          </Typography>
          <Typography color={'text.secondary'} variant={'caption'} minWidth={'51px'}>
            {Math.abs(dod)}%
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
