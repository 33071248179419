import { ApiStore } from './Global/ApiStore';
import { SnackbarStore } from './SnackbarStore';
import { RouterStore } from './RouterStore';
import { AuthStore } from './AuthStore';
import { RootStore } from './StoreManager';
import { makeAutoObservable, observable, runInAction, set, toJS } from 'mobx';
import {
  Code,
  getPropertyByEntity,
  ROLE_EMPLOYEE_CODES,
  RoleEmployee,
  RoleEmployeeTypes,
  selectedCrumb,
} from './EmployeesMarginalityStore';
import { AppUserGroupCodes } from '../types/AppUserGroup';
import { formatDateSwaggerZ } from '@mx-ui/helpers';
import { AxiosResponse } from 'axios';
import {
  BranchOffice,
  BranchOfficeDivision,
  BudgetGroup,
  Employee,
  EmployeeDepartment,
  HeadCompany,
  ReservationReport,
  ReserveIndicators,
  StatReferencesResponse,
  StatReservationsDepartmentsResponse,
  StatReservationsDivisionsResponse,
  StatReservationsEmployeesResponse,
  StatReservationsHeadCompaniesResponse,
  StatReservationsOfficesResponse,
} from '../api/marketx';
import { ParsedUrlQuery } from 'querystring';
import moment from 'moment';
import { ReserveListRequest, ReserveListStore } from './ReserveListStore';

const ReservesTabNamesTypes = <const>['budgetGroups', 'managers', 'offices', 'departments', 'divisions'];
export type ReservesTabNames = (typeof ReservesTabNamesTypes)[number];

export const ReservesTabNamesValue: Record<ReservesTabNames, string> = {
  budgetGroups: 'Бюджетные группы',
  divisions: 'Дивизионы',
  managers: 'Менеджеры',
  departments: 'Отделы',
  offices: 'Филиалы',
};

export const RESERVES_TAB_NAME_CODES = {
  budgetGroups: 'budgetGroups' as ReservesTabNames,
  divisions: 'divisions' as ReservesTabNames,
  managers: 'managers' as ReservesTabNames,
  departments: 'departments' as ReservesTabNames,
  offices: 'offices' as ReservesTabNames,
};

const allowReservesTabsByRole: Record<RoleEmployee, ReservesTabNames[]> = {
  employee: [RESERVES_TAB_NAME_CODES.budgetGroups],
  department: [RESERVES_TAB_NAME_CODES.budgetGroups, RESERVES_TAB_NAME_CODES.managers],
  office: [RESERVES_TAB_NAME_CODES.budgetGroups, RESERVES_TAB_NAME_CODES.departments],
  division: [RESERVES_TAB_NAME_CODES.budgetGroups, RESERVES_TAB_NAME_CODES.offices],
  company: [RESERVES_TAB_NAME_CODES.budgetGroups, RESERVES_TAB_NAME_CODES.divisions],
};

export interface ReservesAllowedParams {
  tab?: ReservesTabNames;
  date?: string;
  selectedCrumbCode?: string;
  mode?: RoleEmployee;
}

export function transformQueryReservesToAllowedParams<T>(query: ParsedUrlQuery, role?: RoleEmployee): T {
  const obj = {} as any;
  if (query.mode && RoleEmployeeTypes.indexOf(query.mode as any) !== -1) {
    obj.mode = query.mode as RoleEmployee;
  } else {
    obj.mode = role;
  }
  if (
    query.mode &&
    query.tab &&
    ReservesTabNamesTypes.indexOf(query.tab as any) !== -1 &&
    allowReservesTabsByRole[query.mode as string].indexOf(query.tab as any) !== -1
  ) {
    obj.tab = query.tab as ReservesTabNames;
  } else {
    obj.tab = RESERVES_TAB_NAME_CODES.budgetGroups;
  }
  if (query.selectedCrumbCode) {
    obj.selectedCrumbCode = query.selectedCrumbCode as string;
  }
  if (query.date && moment(query.date, 'YYYY-MM-DD').isValid()) {
    obj.date = query.date as string;
  }
  return obj;
}

interface ItemsByRoleI {
  employee: Record<string, Employee>;
  office: Record<string, BranchOffice>;
  division: Record<string, BranchOfficeDivision>;
  department: Record<string, EmployeeDepartment>;
  company: Record<string, HeadCompany>;
}

interface IndicatorsByRoleI {
  employee: ReserveIndicators;
  office: ReserveIndicators;
  division: ReserveIndicators;
  department: ReserveIndicators;
  company: ReserveIndicators;
}

export class ReservesReportStore {
  apiStore: ApiStore;
  snackbarStore: SnackbarStore;
  reserveListStore: ReserveListStore;
  routerStore: RouterStore;
  authStore: AuthStore;

  role: RoleEmployee;
  mode: RoleEmployee;
  request: ReservesAllowedParams = null;
  allowTabs: ReservesTabNames[] = [];

  budgetGroups: Record<Code, BudgetGroup> = {};
  indicators: IndicatorsByRoleI = {
    employee: null,
    office: null,
    division: null,
    department: null,
    company: null,
  };
  itemsByRole: ItemsByRoleI = {
    employee: {},
    office: {},
    division: {},
    department: {},
    company: {},
  };
  statsByCode: Record<Code, ReservationReport> = {};

  /**
   * Управляем адресом страницы
   */
  routerControlEnabled = false;
  isLoading = false;
  isLoaded = false;
  isLoadedTabs = {
    budgetGroups: false,
    clients: false,
    managers: false,
    departments: false,
    offices: false,
  };

  selectedCrumbs: Record<RoleEmployee, selectedCrumb> = {
    company: undefined,
    division: undefined,
    office: undefined,
    department: undefined,
    employee: undefined,
  };
  enabledCrumbs: RoleEmployee[] = [];
  crumbsValues: Record<RoleEmployee, selectedCrumb[]> = {
    company: [],
    division: [],
    office: [],
    department: [],
    employee: [],
  };

  selectedTab: ReservesTabNames = RESERVES_TAB_NAME_CODES.budgetGroups;

  private _dateFormat = '';
  isToday = false;
  get date(): Date {
    return new Date(this._dateFormat);
  }
  set date(v) {
    this._dateFormat = formatDateSwaggerZ(v);
  }
  constructor(rootStore: RootStore) {
    this.snackbarStore = rootStore.getSnackbar();
    this.reserveListStore = new ReserveListStore(rootStore);
    this.apiStore = rootStore.getApiStore();
    this.authStore = rootStore.getAuth();
    this.routerStore = rootStore.getRouter();
    this.request = observable({});
    this.role = this.getTypeByRole();
    this.setMode(this.role);
    makeAutoObservable(this, { apiStore: false, snackbarStore: false, getTypeByRole: false });
  }

  setRouterControl(enabled: boolean): void {
    this.routerControlEnabled = enabled;
  }

  getTypeByRole(): RoleEmployee {
    const isDivisionChief = this.authStore.inGroup(AppUserGroupCodes.DIVISION_CHIEF);
    const isDivisionMarketer = this.authStore.inGroup(AppUserGroupCodes.DIVISION_MARKETER);
    const isCallCenterOperator = this.authStore.inGroup(AppUserGroupCodes.CALL_CENTER_OPERATOR);

    const isReserveAdmin = this.authStore.inGroup(AppUserGroupCodes.RESERVE_ADMIN);
    const isReserveManager = this.authStore.inGroup(AppUserGroupCodes.RESERVE_MANAGER);

    const isOfficeChief = this.authStore.inGroup(AppUserGroupCodes.OFFICE_CHIEF);
    const isOfficeMarketer = this.authStore.inGroup(AppUserGroupCodes.OFFICE_MARKETER);
    const isSellerChief = this.authStore.inGroup(AppUserGroupCodes.SELLER_CHIEF);
    const isCompanyChief = this.authStore.inGroup(AppUserGroupCodes.COMPANY_CHIEF);
    const isCompanyMarketer = this.authStore.inGroup(AppUserGroupCodes.COMPANY_MARKETER);
    const isSupport = this.authStore.inGroup(AppUserGroupCodes.SUPPORT);

    if (isCompanyChief || isCompanyMarketer) {
      return ROLE_EMPLOYEE_CODES.company;
    }
    if (isDivisionChief || isDivisionMarketer) {
      return ROLE_EMPLOYEE_CODES.division;
    }
    if (isOfficeChief || isSupport || isOfficeMarketer || isCallCenterOperator || isReserveAdmin || isReserveManager) {
      return ROLE_EMPLOYEE_CODES.office;
    }
    if (isSellerChief) {
      return ROLE_EMPLOYEE_CODES.department;
    }
    return ROLE_EMPLOYEE_CODES.employee;
  }

  setMode(mode: RoleEmployee): boolean {
    if (mode === this.mode) {
      return false;
    }
    const roleIndex = RoleEmployeeTypes.indexOf(this.role);
    const modeParamIndex = RoleEmployeeTypes.indexOf(this.request.mode);
    if (roleIndex > modeParamIndex && this.request.mode) {
      console.warn('Нельзя установить mode выше роли. Значение mode установится по умолчанию');
      this.snackbarStore.showInfo('Вам недоступен просмотр этого уровня. Значения установятся по умолчанию');
      this.mode = this.role;
      return false;
    }
    this.setSelectedTab(RESERVES_TAB_NAME_CODES.budgetGroups);

    this.mode = mode;
    Object.keys(this.isLoadedTabs).forEach(tab => {
      this.isLoadedTabs[tab] = false;
    });
    return true;
  }

  setSelectedTab(value: ReservesTabNames): void {
    this.selectedTab = value;
    this.setRequestFromObj(observable({ tab: value }));
    this.actualizeRouter(this.request);
  }

  setRequestFromObj(req: ReservesAllowedParams): void {
    set(this.request, req);
  }

  actualizeRouter(request: ReservesAllowedParams): void {
    if (!this.routerControlEnabled) {
      return;
    }
    const req = toJS(request);
    this.routerStore.replace(
      {
        pathname: this.routerStore.router.pathname,
        query: { ...req },
      },
      undefined,
      { shallow: true }
    );
  }

  async initialLoadStatsByQuery(params: ReservesAllowedParams, isForHomePage = false): Promise<void> {
    if ((this.role === 'division' || this.role === 'company') && !params.mode && !isForHomePage) {
      params.mode = 'office';
      params.selectedCrumbCode = this.authStore.profile.chosenBranchOfficeCode;
    }
    const role = this.role;
    this.setDate(params.date ? new Date(params.date) : new Date());
    runInAction(async () => {
      this.setRequestFromObj(params);
      if (params.mode) {
        this.setMode(params.mode);
        this.allowTabs = allowReservesTabsByRole[params.mode];
        await this.loadStatMarginalityReferences({ [this.mode]: params.selectedCrumbCode }, true);
      } else {
        this.allowTabs = allowReservesTabsByRole[role];
        await this.loadStatMarginalityReferences({}, true);
      }
      if (params?.tab) {
        this.setSelectedTab(params.tab);
      }
      await this.loadStatByTab(this.selectedTab);
    });
  }

  setDate(date: Date): void {
    this.isToday = formatDateSwaggerZ(date) === formatDateSwaggerZ(new Date());
    this.date = date;
    const localDate = new Date(date);
    this.setRequestFromObj(observable({ date: formatDateSwaggerZ(localDate) }));
    this.actualizeRouter(this.request);
  }

  async loadStatMarginalityReferences(
    {
      employee: employeeCode,
      department: departmentCode,
      office: branchOfficeCode,
      division: divisionCode,
    }: {
      employee?: Code;
      department?: Code;
      office?: Code;
      division?: Code;
    },
    init = false
  ): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await this.apiStore
      .apiStatApi()
      .statReservesReferences(employeeCode, departmentCode, branchOfficeCode, divisionCode, this._dateFormat);
    this.setResultMarginalityReferences(res, init);
  }

  setResultMarginalityReferences({ data }: AxiosResponse<StatReferencesResponse>, init = false): void {
    if (init) {
      const budgetGroups = data.budgetGroups || [];
      this.budgetGroups = budgetGroups.reduce<Record<Code, BudgetGroup>>((acc, i) => {
        acc[i.code] = i;
        return acc;
      }, {});
    }
    this.setCrumbsValues(data);
    if (init) {
      const roleIndex = RoleEmployeeTypes.indexOf(this.role);
      const currentMode = this.mode; //* текущий мод из урла. Если нет, то тут будет мод по роли и дальше все пойдет по маслу.
      const modeParamIndex = RoleEmployeeTypes.indexOf(currentMode);
      if (roleIndex <= modeParamIndex) {
        // * подгружаем данные в крошки, которые идут ДО крошки по моду (включительно)
        const crumbsBeforeRole = RoleEmployeeTypes.slice(0, modeParamIndex + 1).reverse();

        crumbsBeforeRole.forEach((crumbs, index) => {
          if (this.crumbsValues[crumbs].length > 0) {
            if (crumbs === this.mode) {
              //* ищем, для переданной крошки из урла, значение
              const valueByUrl = this.crumbsValues[crumbs].find(crumb => crumb.code === this.request.selectedCrumbCode);
              if (valueByUrl) {
                this.selectedCrumbs[crumbs] = valueByUrl;
              } else {
                //* может быть такое что не нашлось значение и просто берем по дефолту
                this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs][0];
              }
            } /* else if (crumbs == this.role) {
              //* если в урле ничего не передается берем по роли
              this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs][0];
            } */ else {
              //* собираем крошку НЕ переданной из урла и формируем ее на основе Переданной крошки, и остальные так же
              const selectedCrumbsBefore = this.selectedCrumbs[crumbsBeforeRole[index - 1]];
              const selectedCrumbsBeforeCode = selectedCrumbsBefore?.[getPropertyByEntity[crumbs]];
              //* бывает так когда нет у крошки ключа по которой можно ее найти (company не имеет связки с division)
              if (selectedCrumbsBeforeCode) {
                this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs].find(crumb => crumb.code === selectedCrumbsBeforeCode);
              } else {
                this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs][0];
              }
            }
          } else {
            console.warn('crumbsValues with "crumbs" is not exist');
          }
        });
      } else {
        this.snackbarStore.showInfo('Вам недоступен просмотр этого уровня.');
        console.warn('Вам недоступен просмотр этого уровня');
      }
      this.enabledCrumbs = RoleEmployeeTypes.slice(roleIndex, modeParamIndex + 1);
      const nextMode = RoleEmployeeTypes[modeParamIndex + 1];
      if (nextMode && this.crumbsValues[nextMode].length >= 1) {
        if (this.crumbsValues[nextMode].length === 1) {
          this.selectedCrumbs[nextMode] = this.crumbsValues[nextMode][0];
        }
        this.enabledCrumbs.push(nextMode);
      }
    }
    runInAction(() => {
      this.isLoading = false;
    });
  }

  setCrumbsValues(data: StatReferencesResponse): void {
    this.crumbsValues[ROLE_EMPLOYEE_CODES.company] = data.headCompanies || [];
    this.crumbsValues[ROLE_EMPLOYEE_CODES.division] = data.divisions || [];
    this.crumbsValues[ROLE_EMPLOYEE_CODES.office] = [...(data.offices || [])].map(i => ({ ...i, title: i.name }));
    this.crumbsValues[ROLE_EMPLOYEE_CODES.department] = data.departments || [];
    this.crumbsValues[ROLE_EMPLOYEE_CODES.employee] = [...(data.employees || [])].map(employee => ({
      code: employee.code,
      title: `${employee.surname || ''} ${employee.name || ''} ${employee.patronymic || ''}`,
      departmentCode: employee.departmentCode,
    }));
  }

  /**
   * Клик по крошке без меню
   */
  async setModeAndUpdateCrumbs(crumbName: RoleEmployee): Promise<void> {
    const prevMode = this.mode;
    const selectedCrumb = this.selectedCrumbs[crumbName];
    if (!this.setMode(crumbName)) {
      return;
    }
    if (crumbName !== RoleEmployeeTypes[RoleEmployeeTypes.length - 1] && crumbName !== this.role) {
      await this.loadStatMarginalityReferences({ [crumbName]: selectedCrumb.code });
    }
    this.unSelectCrumbs(prevMode, crumbName);
    runInAction(() => {
      this.allowTabs = allowReservesTabsByRole[crumbName];
    });
    this.setRequestFromObj(observable({ mode: crumbName, selectedCrumbCode: selectedCrumb.code }));
    this.actualizeRouter(this.request);
    this.loadStatByTab(RESERVES_TAB_NAME_CODES.budgetGroups);
  }

  unSelectCrumbs(prevMode: RoleEmployee, newMode: RoleEmployee): void {
    const rolePrevIndex = RoleEmployeeTypes.indexOf(prevMode);
    const roleIndex = RoleEmployeeTypes.indexOf(newMode);

    if (roleIndex < rolePrevIndex) {
      const unSelectedCrumb = RoleEmployeeTypes.slice(roleIndex + 1, RoleEmployeeTypes.length);
      unSelectedCrumb.forEach(crumb => {
        this.selectedCrumbs[crumb] = undefined;
      });
      this.enabledCrumbs = this.enabledCrumbs.filter(crumb => !unSelectedCrumb.includes(crumb));
    }
    for (let nextRoleIndex = roleIndex + 1; nextRoleIndex < RoleEmployeeTypes.length; nextRoleIndex++) {
      const nextRole = RoleEmployeeTypes[roleIndex + 1];
      if (nextRole && this.crumbsValues[nextRole].length >= 1) {
        if (this.crumbsValues[nextRole].length === 1) {
          // Выбираем в крошке единственный вариант
          this.selectedCrumbs[nextRole] = this.crumbsValues[nextRole][0];
        } else if (this.selectedCrumbs[nextRole]) {
          // Сбрасываем выбранный вариант, если его нет в доступном списке
          this.selectedCrumbs[nextRole] = this.crumbsValues[nextRole].find(crumb => crumb === this.selectedCrumbs[nextRole]);
        }
        this.enabledCrumbs.push(nextRole);
      } else {
        this.enabledCrumbs = this.enabledCrumbs.filter(crumb => crumb !== nextRole);
      }
    }
  }

  loadStatByTab(tab: ReservesTabNames): void {
    const role = this.mode;
    if (this.isLoadedTabs[tab]) {
      return;
    }
    this.loadBudgetGroupsStatByRole(role);
  }

  loadBudgetGroupsStatByRole(role: RoleEmployee): void {
    runInAction(() => {
      this.statsByCode = {};
      this.itemsByRole = {
        employee: {},
        office: {},
        division: {},
        department: {},
        company: {},
      };
      this.budgetGroups = {};
    });
    switch (role) {
      case 'employee':
        this.loadStatReservesEmployees();
        break;

      case 'department':
        this.loadStatReservesDepartment();
        this.loadStatReservesEmployees(this.selectedCrumbs.department.code);
        break;

      case 'office':
        this.loadStatReservesOffice();
        this.loadStatReservesDepartment(this.selectedCrumbs.office.code);
        break;

      case 'division':
        this.loadStatReservesDivision();
        this.loadStatReservesOffice(this.selectedCrumbs.division.code);
        break;

      case 'company':
        this.loadStatReservesCompany();
        this.loadStatReservesDivision(this.selectedCrumbs.company.code);
        break;

      default:
        console.warn('role received does not exist by loadBudgetGroupsStatByRoleRequest');
        break;
    }
    this.loadReservesDocs();
  }

  loadStatReservesCompany(): void {
    const date = this.date;
    runInAction(() => {
      this.isLoading = true;
    });

    this.apiStore
      .apiStatApi()
      .statReservationsHeadCompanies(this.selectedCrumbs.company.code, formatDateSwaggerZ(new Date(date)))
      .then(res => {
        this.setStatReservesMangerResult(res.data, 'headCompanyCode');
      })
      .catch(err => {
        console.warn('loadStatReservesCompanyRequest', err);
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  loadStatReservesDivision(headCompanyCode?: string): void {
    const date = this.date;
    runInAction(() => {
      this.isLoading = true;
    });
    this.apiStore
      .apiStatApi()
      .statReservationsDivisions(headCompanyCode, headCompanyCode ? undefined : this.selectedCrumbs.division.code, formatDateSwaggerZ(date))
      .then(res => {
        this.setStatReservesMangerResult(res.data, 'divisionCode');
      })
      .catch(err => {
        console.warn('loadStatReservesDivisionRequest', err);
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  loadStatReservesOffice(divisionCode?: string): void {
    const date = this.date;
    runInAction(() => {
      this.isLoading = true;
    });
    this.apiStore
      .apiStatApi()
      .statReservationsOffices(divisionCode, divisionCode ? undefined : this.selectedCrumbs.office.code, formatDateSwaggerZ(date))
      .then(res => {
        this.setStatReservesMangerResult(res.data, 'officeCode');
      })
      .catch(err => {
        console.warn('loadStatReservesOfficeRequest', err);
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  loadStatReservesDepartment(officeCode?: string): void {
    const date = this.date;
    runInAction(() => {
      this.isLoading = true;
    });
    this.apiStore
      .apiStatApi()
      .statReservationsDepartments(officeCode, officeCode ? undefined : this.selectedCrumbs.department?.code, formatDateSwaggerZ(date))
      .then(res => {
        this.setStatReservesMangerResult(res.data, 'departmentCode');
      })
      .catch(err => {
        console.warn('loadStatReservesDepartmentRequest', err);
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  loadStatReservesEmployees(departmentCode?: string): void {
    const date = this.date;
    runInAction(() => {
      this.isLoading = true;
    });
    this.apiStore
      .apiStatApi()
      .statReservationsEmployees(departmentCode, departmentCode ? undefined : this.selectedCrumbs.employee?.code, formatDateSwaggerZ(date))
      .then(res => {
        this.setStatReservesMangerResult(res.data, 'employeeCode');
      })
      .catch(err => {
        console.warn('loadStatReservesEmployeesRequest', err);
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  updateStats(): void {
    //* при обновлении даты запрашиваем еще новые данные для крошек, т.к. со временем могут добавляться новые менеджеры к отделу и новые отделы
    if (this.mode === ROLE_EMPLOYEE_CODES.department || this.mode === ROLE_EMPLOYEE_CODES.office) {
      this.loadStatMarginalityReferences({ [this.mode]: this.selectedCrumbs[this.mode]?.code });
    }
    Object.keys(this.isLoadedTabs).forEach(tab => {
      this.isLoadedTabs[tab] = false;
    });
    this.loadStatByTab(RESERVES_TAB_NAME_CODES.budgetGroups);
    if (RESERVES_TAB_NAME_CODES.budgetGroups !== this.selectedTab) {
      this.loadStatByTab(this.selectedTab);
    }
  }

  /**
   * Выбираем значение из списка крошки.
   */
  async setSelectedCrumbAndSetMode(crumbName: RoleEmployee, code: Code): Promise<void> {
    const prevMode = this.mode;
    const prevSelectedCrumbsCode = this.selectedCrumbs[crumbName]?.code;
    const selectedCrumb = this.crumbsValues[crumbName].find(crumb => crumb.code === code);

    if (!this.setMode(crumbName) && prevSelectedCrumbsCode === selectedCrumb.code) {
      return;
    }
    this.setRequestFromObj(observable({ mode: crumbName, selectedCrumbCode: selectedCrumb.code }));
    this.actualizeRouter(this.request);
    Object.keys(this.isLoadedTabs).forEach(tab => {
      this.isLoadedTabs[tab] = false;
    });
    // * если роль последняя то обновляем References(т.к. следующей крошки после этой роли нет, значит можно не обновлять данные)
    if (crumbName !== RoleEmployeeTypes[RoleEmployeeTypes.length - 1]) {
      await this.loadStatMarginalityReferences({ [crumbName]: selectedCrumb.code });
    }
    this.unSelectCrumbs(prevMode, crumbName);
    runInAction(() => {
      this.allowTabs = allowReservesTabsByRole[crumbName];
      this.selectedCrumbs[crumbName] = selectedCrumb;
    });

    this.loadStatByTab(this.selectedTab);
  }

  setStatReservesMangerResult(
    data:
      | StatReservationsHeadCompaniesResponse
      | StatReservationsDivisionsResponse
      | StatReservationsOfficesResponse
      | StatReservationsDepartmentsResponse
      | StatReservationsEmployeesResponse,
    codeForStat: string
  ): void {
    if (data.budgetGroups?.length) {
      data.budgetGroups.forEach(bg => {
        this.budgetGroups[bg.code] = bg;
      });
    }

    if ('employees' in data && data.employees?.length) {
      data.employees.forEach(employee => {
        this.itemsByRole.employee[employee.code] = employee;
      });
      this.indicators.employee = data.indicators;
    }
    if ('departments' in data && data.departments?.length) {
      this.indicators.department = data.indicators;
      data.departments.forEach(dp => {
        this.itemsByRole.department[dp.code] = dp;
      });
    }
    if ('offices' in data && data.offices?.length) {
      this.indicators.office = data.indicators;
      data.offices.forEach(of => {
        this.itemsByRole.office[of.code] = of;
      });
    }
    if ('divisions' in data && data.divisions?.length) {
      this.indicators.division = data.indicators;
      data.divisions.forEach(dv => {
        this.itemsByRole.division[dv.code] = dv;
      });
    }
    if ('headCompanies' in data && data.headCompanies?.length) {
      this.indicators.company = data.indicators;
      data.headCompanies.forEach(hc => {
        this.itemsByRole.company[hc.code] = hc;
      });
    }

    if (data.reports?.length) {
      data.reports.forEach(report => {
        this.statsByCode[report[codeForStat]] = report.report;
      });
    }

    runInAction(() => {
      this.isLoading = false;
      this.isLoaded = true;
    });
  }

  loadReservesDocs(): void {
    if (!this.isToday) {
      return;
    }
    if (this.selectedCrumbs.office?.code) {
      this.reserveListStore.loadWarehouseReportList(this.selectedCrumbs.office?.code);
    }
    let request: ReserveListRequest = {
      page: 1,
      countPerPage: 15,
      autoReserveEnabled: false,
    };
    const role = this.mode;
    switch (role) {
      case 'employee':
        request = {
          ...request,
          employeeCodes: this.selectedCrumbs.employee?.code ? [this.selectedCrumbs.employee?.code] : undefined,
        };
        break;

      case 'department':
        request = {
          ...request,
          departmentCodes: this.selectedCrumbs.department?.code ? [this.selectedCrumbs.department?.code] : undefined,
        };
        break;

      case 'office':
        request = {
          ...request,
          branchOfficeCodes: this.selectedCrumbs.office?.code ? [this.selectedCrumbs.office?.code] : undefined,
        };
        break;

      case 'division':
        request = {
          ...request,
          divisionCodes: this.selectedCrumbs.division?.code ? [this.selectedCrumbs.division?.code] : undefined,
        };
        break;

      case 'company':
        request = {
          ...request,
          headCompanyCode: this.selectedCrumbs.company?.code ? this.selectedCrumbs.company?.code : undefined,
        };
        break;

      default:
        console.warn('role received does not exist by loadReservesDocs');
        break;
    }

    this.reserveListStore.mergeRequestForReport(request);
  }
}
